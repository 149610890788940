import classNames from 'classnames';
import React from 'react';
import { usePage } from '../hooks/usePage';
import Footer from './common/Footer';
import Header from './common/Header';

export default function Layout({ children }) {
  const { backgroundClasses, paddingDisabled } = usePage();
  return (
    <>
      <div
        className={classNames(
          'w-full flex-grow mx-auto flex flex-col items-center overflow-hidden',
          backgroundClasses
        )}
      >
        <Header />
        <div
          className={classNames('flex-grow z-5 w-full max-w-4xl mx-auto flex', {
            'p-6': !paddingDisabled
          })}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
}
