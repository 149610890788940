const config = {
  api: {
    uri: window._env_.API_URI,
  },
  app: {
    version: window._env_.VERSION,
    gitSha: window._env_.GIT_SHA,
    builtAt: window._env_.BUILT_AT,
  },
  consoleLoggingEnabled: window._env_.CONSOLE_LOGGING_ENABLED,
  env: window._env_.ENV,
  logLevel: window._env_.LOG_LEVEL,
  rollbar: {
    accessToken: window._env_.ROLLBAR_ACCESS_TOKEN,
    captureUncaught: window._env_.ROLLBAR_CAPTURE_UNCAUGHT,
    captureUnhandled: window._env_.ROLLBAR_CAPTURE_UNHANDLED,
    enabled: window._env_.ROLLBAR_ENABLED,
  },
  helpUrl:window._env_.HELP_URL??'https://zoehelpdesk.zendesk.com/hc/en-us/requests/new',
};

export { config as default };
