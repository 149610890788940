

export class AssessmentSections {
  static NotStarted = 0;
  static CliftonStrengths = 10;
  static CoreValues = 20;
  static CareerKey = 30;
  static BringingItAllTogether = 50;
}

export class CliftonStrengthsFinderStep {
  static StrengthsFinderIntro = 0;
  static StrengthsFinderPreFeedback = 1;
  static StrengthsFinderCode = 2;
  static StrengthsFinderAssign = 3;
  static StrengthsFinderDetails = 4;
  static StrengthsFinderFeedback = 5;
  static StrengthFinderComplete = 9;
}


export class CompletedJourneyStep {
  static PutTogether1 = 0;
  static PutTogether2 = 1;
  static FinalQuestions = 2;
  static PutTogether3 = 3;
  //static AdditionalOptions = 4;
}


export class AssessmentSteps {
  static NotStarted = AssessmentSections.NotStarted;
  static CliftonStrengthFindersIntro = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderIntro;
  static CliftonStrengthFindersPreFeedback = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderPreFeedback;
  static CliftonStrengthsFinderCode = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderCode;
  static CliftonStrengthsFinderAssigned = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderAssign;
  static CliftonStrengthFindersDetails = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderDetails;
  static CliftonStrengthFindersFeedback = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthsFinderFeedback;
  static CliftonStrengthsFinderCompleted = AssessmentSections.CliftonStrengths + CliftonStrengthsFinderStep.StrengthFinderComplete;

  static CoreValuesIntro = AssessmentSections.CoreValues;
  static CoreValuesPrefeedback = AssessmentSections.CoreValues + 1;
  static CoreValuesStep1Completed = AssessmentSections.CoreValues + 2;
  static CoreValuesStep2Completed = AssessmentSections.CoreValues + 3;
  static CoreValuesStep3Completed = AssessmentSections.CoreValues + 4;
  static CoreValuesStep4Reflection = AssessmentSections.CoreValues + 6;
  static CoreValuesCompleted = AssessmentSections.CoreValues + 9;

  static CareerKeyStarted = AssessmentSections.CareerKey;
  static CareerKeyIntroCompleted = AssessmentSections.CareerKey + 1;
  static CareerKeyStep1Completed = AssessmentSections.CareerKey + 2;
  static CareerKeyStep2Completed = AssessmentSections.CareerKey + 3;
  static CareerKeyStep3Completed = AssessmentSections.CareerKey + 4;
  static CareerKeyStep4Completed = AssessmentSections.CareerKey + 5;
  static CareerKeyStep5Completed = AssessmentSections.CareerKey + 6;
  static CareerKeyStep6Completed = AssessmentSections.CareerKey + 7;
  static CareerKeyCompleted = AssessmentSections.CareerKey + 9;

  static BringingItAllTogetherStarted = AssessmentSections.BringingItAllTogether ;
  static BringingItAllTogether1 = AssessmentSections.BringingItAllTogether + CompletedJourneyStep.PutTogether1;
  static BringingItAllTogether2 = AssessmentSections.BringingItAllTogether + CompletedJourneyStep.PutTogether2;
  static BringingItAllTogetherQuestions = AssessmentSections.BringingItAllTogether + CompletedJourneyStep.FinalQuestions;
  static BringingItAllTogether3 = AssessmentSections.BringingItAllTogether + CompletedJourneyStep.PutTogether3;
  // static Bring

  static Complete = 99;
}


export class AssessmentStepHelper{

  //Basically, needs to be able to be called in any place
  //but return the next step even in scenarios
  //Where a user is reviewing a previous screen.
  static getNextStep(currentStep, maxPotentialStep) {
      let steps = this.getSteps(AssessmentSteps);
      let pulledStep = this.getStep(steps, currentStep);
      let pulledMaxPotentialStep =  this.getStep(steps, maxPotentialStep);

      let index = steps.indexOf(pulledStep);
      let indexMaxStep = steps.indexOf(pulledMaxPotentialStep);
      if(index == steps.length)
      {
        return AssessmentSteps.Complete;
      }
      else if(indexMaxStep >= 0 && index >= indexMaxStep){
        let result = steps[index]?.order;
        return result;
      }
      else
      {
        // return steps[index + 1]?.order;
        let result = steps[index + 1]?.order;
        return result;
      }
  }

  static getStep(steps, stepOrder){
    let pulledStep = steps.filter(x => {
      //console.log(x);
      return x.order == stepOrder}
    )[0];

    return pulledStep;
  }

  static getSteps(valueClass){
    let stepNames = this.getNames(valueClass)

    let steps = stepNames.map(x => {
      let step = { name: x, order: valueClass[x]};
      // step[x] = AssessmentSteps[x];
      // Reflect.set(step, x, AssessmentSteps[x]);
      return step;
    }).sort((a, b) => a.order - b.order);

    return steps;
  }
  
  static getStartingStep(assessmentSection){
    let sectionNames = '';
  }

  static getMaxStep(assessmentSection){
    return '';
  }

  static getNames(valueClass){
    const unwantedNames = ['length', 'name', 'prototype'];
    let stepNames = Reflect.ownKeys(valueClass).filter(x => {
      return unwantedNames.indexOf(x) < 0; 
    });
    return stepNames;
  }

}



export class InvitationStatus {
  static Added = 0;
  static PendingInvitation = 1;
  static InvitationSent = 2;
  static Accepted = 3;
  static Declined = 4;
}

export class OnboardingStep {
  static ExploreYourPurpose = 0;
  static AboutZoe = 1;
  static PowerOfThree = 2;
  static FindingYourPurpose = 3;
  static LetsLearnMoreAboutYou = 4;
  static Complete = 5;
}


export class FeedbackStepCoreValues {
  static NotStarted = 0;
  static Started = 10;
  static IntroCompleted = 20;
  static FeedbackCompleted = 30;
  static Complete = 99;
}

export class FeedbackStepCareerKey {
  static NotStarted = 0;
  static Started = 10;
  static IntroCompleted = 20;
  static AboutCompleted = 30;
  static HollandCompleted = 40;
  static ResultsCompleted = 50;
  static ResultsReflectionCompleted = 60;
  static BookmarksCompleted = 70;
  static Complete = 99;
}

export class FeedbackStepCliftonStrengthsfinder {
  static NotStarted = 0;
  static Started = 10;
  static IntroCompleted = 20;
  static FeedbackCompleted = 30;
  static Complete = 99;
}

export class SessionStateKeys {
  static AccessToken = 'zoe-accessToken';
  static RefreshToken = 'zoe-refreshToken';
  static User = 'zoe-user';
}

export class CareerKeyEnvironment {
  static Promising = 'promising';
  static Thriving = 'thriving';
  static challenging = 'challenging';
}

export class LinkType {
  static Internal = 'internal';
  static External = 'external';
  static Label = 'label';

}

export class SectionStage {
  static Intro = 0;
  static Prefeedback = 1;
  static Questions = 2;
  static Results = 3;
}

export class CareersorMajors {
  static Careers = 'careers';
  static Majors = 'majors';
}

export class CareerKeyColors {
  static Promising = 'orange';
  static Thriving = 'blue';
  static Challenging = 'grey';
}

export const Traits = [
  {
    type: 'Realistic',
    oneWord: 'Practical',
    twoWords: 'Practical and Genuine',
    oneSentance: 'You see yourself as practical with mechanical ability.',
    environment: 'Realistic environments reward practicality and making better things you can see or touch.',
    compatibleHollandTupes: 'Conventional and Investigative'
  },
  {
    type: 'Investigative',
    oneWord: 'Precise',
    twoWords: 'Precise and Intellectual',
    oneSentance: 'You see yourself as curious, independent, and good at understanding math and science.',
    environment: 'Investigative environments reward seeing the world in complex and abstract ways.',
    compatibleHollandTupes: 'Artistic and Realistic'
  },
  {
    type: 'Artistic',
    oneWord: 'Expressive',
    twoWords: 'Expressive and Independent',
    oneSentance: 'You see yourself as expressive, original, and independent with artistic ability.',
    environment: 'Artistic environments reward originality and creativity.',
    compatibleHollandTupes: 'Investigative and Social'
  },
  {
    type: 'Social',
    oneWord: 'Helpful',
    twoWords: 'Helpful and Understanding',
    oneSentance: 'You see yourself as helpful, friendly, and trustworthy to the people around you.',
    environment: 'Social environments reward helping people, friendliness, and solving social problems.',
    compatibleHollandTupes: 'Artistic and Enterprising'
  },
  {
    type: 'Enterprising',
    oneWord: 'Ambitious',
    twoWords: 'Energetic and Ambitious',
    oneSentance: 'You see yourself as sociable and good at selling things and ideas.',
    environment: 'Enterprising environments reward leadership, assertivenss, and ambition.',
    compatibleHollandTupes: 'Conventional and Social'
  },
  {
    type: 'Conventional',
    oneWord: 'Great at planned tasks',
    twoWords: 'Orderly and Conscientious',
    oneSentance: 'You see yourself as good at following plans, persistent, and working in an orderly way.',
    environment: 'Conventional environments reward practical problem solving and careful planning.',
    compatibleHollandTupes: 'Enterprising and Realistic'
  }
];

