import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHiking } from '@fortawesome/free-solid-svg-icons';

const StepsProgressBar = ({ totalSteps, currentProgressStep }) => {
  let stepsList=[];
  for(var i=0; i < totalSteps; i++){
        stepsList.push(<div key={i} className="mr-1">
          <FontAwesomeIcon icon={faHiking} style={i<currentProgressStep ? {} : {color:'White'}}/>
        </div>);
  };

  return (
    <div className='flex absolute top-0 right-0' style={{marginTop:'12px', marginRight:'40px'}}>
         {stepsList}
    </div>
  );
};

export default StepsProgressBar;