export const requestHostInterceptor = host => () => async action => {
  var cacheBreaker = '';
  if (action.method === 'GET') {
    if (action.endpoint.indexOf('?') < 0) {
      if (action.endpoint[action.endpoint.length - 1] !== '/') {
        action.endpoint += '/';
      }
      cacheBreaker += '?';
    } else {
      cacheBreaker += '&';
    }
    cacheBreaker += '_=' + Math.random();
  }

  if (action.endpoint.indexOf(host) !== 0) {
    return {
      ...action,
      originalEndpoint: action.endpoint,
      endpoint: `${host}${action.endpoint}${cacheBreaker}`
    };
  } else {
    return {
      ...action,
      originalEndpoint: action.endpoint,
      endpoint: `${action.endpoint}${cacheBreaker}`
    };
  }
};
