import classNames from 'classnames';
import React from 'react';

export const ZoeLogoType = {
  Color: 'Zoe-logo-color-login.svg',
  Color_NoTag: 'Zoe-logo-color-login-no-tagline.svg',
  Black: 'zoe-z-icon-black.svg',
  White: 'zoe-z-icon-white.svg'
};
const ZoeLogo = ({ type, className }) => (
  <img
    src={`/images/${type}`}
    className={classNames('self-center', className)}
    alt='Zoe Logo'
  />
);

export default ZoeLogo;
