const errorInterceptor = logApiClientError => client => async (action, response) => {
  // Global error logging on API failures, will only catch errors with responses.
  // CORS or network errors won't be caught here because the server was not reached
  // those types of errors will have to be handled individually where the request is being made
  if (response.status > 400) {
    logApiClientError(action.method, action.endpoint, response.status, response.errorObject);
  }

  return response;
};

export { errorInterceptor as default };
