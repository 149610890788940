import React from 'react';
import { withRouter } from 'react-router-dom';

const Footer = ({ location, history }) => {
  const showFooter = pathname => {
    return (
      pathname === '/set-password' ||
      pathname === '/login' ||
      pathname === '/join'
    );
  };

  return (
    showFooter(location.pathname) && (
      <div className='w-full text-center text-xs uppercase mx-auto pb-6 '>
        Powered by Via Vita
      </div>
    )
  );
};

export default withRouter(Footer);
