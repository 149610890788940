import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ClientContextProvider } from 'react-fetching-library';
import client from './api/Client';
import App from './App';
import Loading from './components/common/Loading';
import { AuthProvider } from './hooks/useAuth';
import { CareerKeyProvider } from './hooks/useCareerKey';
import { JourneyProvider } from './hooks/useJourney';
import { PageProvider } from './hooks/usePage';
import { SystemProvider } from './hooks/useSystem';
import { LoggerProvider, LoggerContext } from './hooks/useLogger';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <LoggerProvider>
    <LoggerContext.Consumer>
      {({ logApiClientError, logWarning }) => (
        <ClientContextProvider client={client(logApiClientError, logWarning)}>
          <Suspense fallback={<Loading />}>
            <>
              <SystemProvider>
                <AuthProvider>
                  <PageProvider>
                    <JourneyProvider>
                      <CareerKeyProvider>
                        <App />
                      </CareerKeyProvider>
                    </JourneyProvider>
                  </PageProvider>
                </AuthProvider>
              </SystemProvider>
            </>
          </Suspense>
        </ClientContextProvider>
      )}
    </LoggerContext.Consumer>
  </LoggerProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
