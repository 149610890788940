import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useJourney } from '../hooks/useJourney';
import AuthorizedRoute from './AuthorizedRoute';

const JourneyRoute = ({ component: Component, path, location, ...rest }) => {
  const { journeyId } = useJourney();
  const { isAuthenticated, ready } = useAuth();

  const render = props => <Component {...props} />;

  const journeySelected = !!journeyId;

  return !ready || !isAuthenticated || journeySelected ? (
    <AuthorizedRoute
      path={path}
      location={location}
      render={render}
      {...rest}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  );
};

export default JourneyRoute;
