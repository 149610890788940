import React from 'react';
import ZoeLogo, { ZoeLogoType } from './ZoeLogo';

const Error = ({ title, message, children }) => (
  <div className='flex-grow w-full flex flex-col p-6 '>
    <div className='flex-grow w-full flex flex-col items-start'>
      <ZoeLogo type={ZoeLogoType.Color} className={'w-56 pt-2 pb-10 mx-auto'} />
      <h1 className='h1 w-full pb-6 text-center text-red'>
        {title || 'An error occured'}
      </h1>
      <div className='w-full'>
        <p className='text-center w-auto'>{message}</p>
      </div>
      {children && 
        <div className='w-full pt-8'>
          {children}
        </div>
      }
    </div>
  </div>
);

export default Error;
