import React from 'react';

const Subeader = ({ text }) => {
  return (
    <div className='w-full mx-auto relative  justify-center bg-alt-gray'>
        <div className='flex flex-wrap items-center justify-center mx-auto'>
         <h1 className='h1r p-2'>{text}</h1>
        </div>
    </div>
  );
};

export default Subeader;
