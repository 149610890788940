import React from 'react';
import { withRouter } from 'react-router-dom';
import { usePage } from '../../hooks/usePage';
import Menu from './Menu';
import ZoeLogo, { ZoeLogoType } from '../common/ZoeLogo';
import StepsProgressBar from '../common/StepsProgressBar';
import ReactHtmlParser from 'react-html-parser';
import Subheader from './Subheader';

const Header = ({ location }) => {
  const { title, headerVisible, menuVisible, subheaderVisible, subheaderText, logoVisible, stepsProgressVisible, totalSteps, currentProgressStep, headerLogoType } = usePage();
  return (
    <div className='header w-full mx-auto relative justify-center'>
      {headerVisible && (
        <div className='p-h flex header-title flex-wrap items-center justify-center mx-auto'>
          <div className='header-title-logo flex'>
            {logoVisible && (<><div className='flex-none'>
              <ZoeLogo type={headerLogoType} className='w-16 pt-2 pb-2 mr-5 mx-auto' />
            </div>
            </>)}
            <div className='flex items-center justify-center flex-wrap '>
              <h2 className='w-full text-center font-extrabold whitespace-pre-wrap pt-5 pb-5 tr'>
                {ReactHtmlParser(title)}
              </h2>
            </div>
          </div>
        </div>
      )}
        {stepsProgressVisible && <StepsProgressBar totalSteps={totalSteps} currentProgressStep={currentProgressStep}></StepsProgressBar>}
        {menuVisible && <Menu location={location} />}
        {subheaderVisible && <Subheader text={subheaderText} />}
    </div>
  );
};

export default withRouter(Header);
