import React from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loading from './components/common/Loading';
import Layout from './components/Layout';
import { useAuth } from './hooks/useAuth';
import AuthorizedRoute from './routing/AuthorizedRoute';
import JourneyRoute from './routing/JourneyRoute';
import OnboardedJourneyRoute from './routing/OnboardedJourneyRoute';

function App() {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Layout>
        <Switch>
          <AuthorizedRoute path="/" exact component={AsyncDashboard} />
          <AuthorizedRoute path="/onboarding" exact component={AsyncOnboarding} />
          <AuthorizedRoute path="/completed" exact component={AsyncCompleted} />
          <AuthorizedRoute path="/profile" exact component={AsyncProfile} />
          <AuthorizedRoute path="/preferences" exact component={AsyncPreferences} />
          <JourneyRoute path="/tasks" exact component={AsyncTasks} />
          <JourneyRoute path="/core-values" exact component={AsyncCoreValues} />
          <JourneyRoute
            path="/clifton-strengthsfinder"
            exact
            component={AsyncCliftonStrengthsfinder}
          />
          <JourneyRoute path="/career-key" exact component={AsyncCareerKey} />
          <AuthorizedRoute path="/results" exact component={AsyncResults} />
          <AuthorizedRoute path="/share" exact component={AsyncShare} />
          <AuthorizedRoute path="/next-steps" exact component={AsyncNextSteps} />
          <AuthorizedRoute path="/careers-majors" exact component={AsyncCareersAndMajors} />

          <Route path="/set-password" component={AsyncSetPassword} />
          <Route path="/reset-password" component={AsyncResetPassword} />
          <Route path="/forgot-password" component={AsyncForgotPassword} />
          <Route path="/login" component={AsyncLogin} />
          <Route path="/styles" component={AsyncStyles} />
        </Switch>
      </Layout>
    </Router>
  );
}

const AsyncResults = Loadable({
  loader: () => import('./pages/Results'),
  loading: Loading,
});

const AsyncShare = Loadable({
  loader: () => import('./pages/Share'),
  loading: Loading,
});

const AsyncNextSteps = Loadable({
  loader: () => import('./pages/NextSteps'),
  loading: Loading,
});

const AsyncDashboard = Loadable({
  loader: () => import('./pages/Dashboard'),
  loading: Loading,
});

const AsyncProfile = Loadable({
  loader: () => import('./pages/Profile'),
  loading: Loading,
});

const AsyncPreferences = Loadable({
  loader: () => import('./pages/Preferences'),
  loading: Loading,
});

const AsyncOnboarding = Loadable({
  loader: () => import('./pages/Onboarding'),
  loading: Loading,
});

const AsyncCareersAndMajors = Loadable({
  loader: () => import('./pages/CareersAndMajorsPage'),
  loading: Loading,
});

const AsyncCompleted = Loadable({
  loader: () => import('./pages/Completed'),
  loading: Loading,
});

const AsyncCoreValues = Loadable({
  loader: () => import('./pages/CoreValues'),
  loading: Loading,
});

const AsyncCliftonStrengthsfinder = Loadable({
  loader: () => import('./pages/CliftonStrengthsfinder'),
  loading: Loading,
});

const AsyncCareerKey = Loadable({
  loader: () => import('./pages/CareerKey'),
  loading: Loading,
});

const AsyncTasks = Loadable({
  loader: () => import('./pages/Tasks'),
  loading: Loading,
});

const AsyncSetPassword = Loadable({
  loader: () => import('./pages/SetPassword'),
  loading: Loading,
});

const AsyncResetPassword = Loadable({
  loader: () => import('./pages/ResetPassword'),
  loading: Loading,
});

const AsyncForgotPassword = Loadable({
  loader: () => import('./pages/ForgotPassword'),
  loading: Loading,
});

const AsyncLogin = Loadable({
  loader: () => import('./pages/Login'),
  loading: Loading,
});

//This is temporary just to view global styles
const AsyncStyles = Loadable({
  loader: () => import('./pages/Styles'),
  loading: Loading,
});

export default App;
