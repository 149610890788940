import React, { useEffect, useState, useContext } from 'react';
import Rollbar from 'rollbar';
import config from '../config';

export const LoggerContext = React.createContext();
export const useLogger = () => useContext(LoggerContext);

// TODO: should provide an object with all the logging methods
export const LoggerProvider = props => {
  const [rollbarClient, setRollbarClient] = useState();

  const logLevels = {
    CRITICAL: 50,
    ERROR: 40,
    WARNING: 30,
    INFO: 20,
    DEBUG: 10,
  };

  // Default to log level of ERROR if not specified in env config
  const logLevel =
    config.logLevel && logLevels[config.logLevel.toUpperCase()]
      ? logLevels[config.logLevel.toUpperCase()]
      : logLevels.ERROR;

  useEffect(() => {
    if (config.rollbar.enabled === 'true' && !rollbarClient) {
      const client = new Rollbar({
        accessToken: config.rollbar.accessToken,
        captureUncaught: config.rollbar.captureUncaught,
        captureUnhandledRejections: config.rollbar.captureUnhandled,
      });

      client.configure({
        payload: {
          environment: config.env,
        },
      });

      setRollbarClient(client);
    }
  }, []);

  const consoleLog = (level, err) => {
    if (config.consoleLoggingEnabled === 'true') {
      console.log(`${level}: ${err}`);
    }
  };

  const logCritical = err => {
    if (logLevel <= logLevels.CRITICAL) {
      if (rollbarClient) {
        rollbarClient.critical(err);
      }
      consoleLog('Critical', err);
    }
  };

  const logError = err => {
    if (logLevel <= logLevels.ERROR) {
      if (rollbarClient) {
        rollbarClient.error(err);
      }
      consoleLog('Error', err);
    }
  };

  // helper for api specific errors
  const logApiClientError = (method, endpoint, status, errorObj) => {
    logError(
      `API Client failed calling ${method} ${endpoint} - Status: ${status} Error: ${errorObj}`
    );
  };

  const logWarning = err => {
    if (logLevel <= logLevels.WARNING) {
      if (rollbarClient) {
        rollbarClient.warning(err);
      }
      consoleLog('Warn', err);
    }
  };

  const logInfo = err => {
    if (logLevel <= logLevels.INFO) {
      if (rollbarClient) {
        rollbarClient.info(err);
      }
      consoleLog('Info', err);
    }
  };

  const logDebug = err => {
    if (logLevel <= logLevels.DEBUG) {
      if (rollbarClient) {
        rollbarClient.debug(err);
      }
      consoleLog('Debug', err);
    }
  };

  const setUserInfo = user => {
    if (rollbarClient) {
      rollbarClient.configure({
        payload: {
          person: {
            uuid: user.uuid,
            email: user.email,
          },
          environment: config.env,
        },
      });
    }
  };

  return (
    <LoggerContext.Provider
      value={{
        logCritical,
        logError,
        logWarning,
        logInfo,
        logDebug,
        setUserInfo,
        logApiClientError,
      }}
      {...props}
    />
  );
};
