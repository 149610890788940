import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import {
  AssessmentSteps,
  LinkType,
  CompletedJourneyStep,
  OnboardingStep
} from '../../constants';
import { usePage } from '../../hooks/usePage';
import { useAuth } from '../../hooks/useAuth';
import { useJourney } from '../../hooks/useJourney';
import config from '../../config';

const Menu = ({ history }) => {
  const [isOpened, setOpened] = useState(false);
  const { setMenuCurrentStep, setMenuCurrentStage } = usePage();
  const { logout } = useAuth();
  const [step] = useState();

  const { journey } = useJourney();

  const [completedJourney, setCompletedJourney] = useState(false);

  useEffect(() => {
    setCompletedJourney(!!journey && journey.assessmentStep === AssessmentSteps.Complete);
  }, [journey]);

  const toggleOpen = item => {
    //if this was passed navigation info
    if (item?.title) {
      setMenuCurrentStep(item?.params?.step);
      setMenuCurrentStage(item?.params?.stage);
    }
    setOpened(!isOpened);
  };

  const { reset } = useJourney();

  const links = [
    {
      title: 'Home',
      type: LinkType.Internal,
      pathname: '/onboarding',
      params: 
      {
        step: OnboardingStep.ExploreYourPurpose
      },
    },
    {
      title: 'Dashboard',
      type: LinkType.Internal,
      pathname: '/',
    },
    {
      title: 'Bringing it together',
      type: LinkType.Internal,
      pathname: '/completed',
      params: 
      {
        step: CompletedJourneyStep.PutTogether2
      },
      isHidden: !completedJourney
    },
    {
      title: 'Your purpose',
      type: LinkType.Internal,
      pathname: '/completed',
      params: 
      {
        step: CompletedJourneyStep.PutTogether3
      },
      isHidden: !completedJourney
    },
    {
      title: 'Careers & Majors',
      type: LinkType.Internal,
      pathname: '/careers-majors',
      isHidden: !completedJourney
    },
    {
      title: 'Share',
      type: LinkType.Internal,
      pathname: '/share',
      spacer: true,
      isHidden: !completedJourney
    },
    {
      title: 'Profile',
      type: LinkType.Internal,
      pathname: '/profile',
    },
    {
      title: 'Preferences',
      type: LinkType.Internal,
      pathname: '/preferences',
    },
    {
      title: 'FAQs',
      type: LinkType.External,
      pathname: 'https://www.exploremypurpose.com/faqs',
    },
    {
      title: 'Support',
      type: LinkType.External,
      pathname: 'https://www.exploremypurpose.com/supportrequest',
    },
  ];

  const doLogout = () => {
    logout();
    reset();
    history.push('/login');
  };

  return (
    <MenuView
      opened={isOpened}
      onToggle={toggleOpen}
      onLogout={doLogout}
      navigatedStep={step}
      links={links}
    />
  );
};

const MenuView = ({ opened, onToggle, onLogout, links }) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (opened && wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onToggle && onToggle();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div
      className={classNames('absolute  top-0 right-0 rounded', {
        'pt-1 pr-1': opened,
      })}
      ref={wrapperRef}
    >
      {opened ? (
        <MenuOpenedView onToggle={onToggle} onLogout={onLogout} links={links} />
      ) : (
        <button
          className={classNames('p-2 pt-4 pr-3 text-white menu-open-button')}
          onClick={onToggle}
          height="16"
          width="21"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 16" height="21" width="16">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path d="M21,2H0V0H21Zm0,5H0V9H21Zm0,7H0v2H21Z" />
              </g>
            </g>
          </svg>
        </button>
      )}
    </div>
  );
};

const MenuItemView = withRouter(({ location, linkItem, onClick }) => {
  const active =
    location.pathname === linkItem.pathname &&
    location?.state?.step === linkItem?.params?.step &&
    location?.state?.stage === linkItem?.params?.stage;
  const isInternal = linkItem.type === LinkType.Internal;
  return linkItem.isHidden ? <></> : (isInternal ? (
    <Link
      to={{
        pathname: linkItem.pathname,
        state: linkItem.params,
      }}
      //Need to update state with step, null it out if need be
      onClick={() => onClick(linkItem)}
      className={classNames('py-1 px-2 no-underline menu--item', {
        'menu--item-active cursor-default': active,
      })}
    >
      {ReactHtmlParser(linkItem.title)}
    </Link>
  ) : linkItem.type === LinkType.External ? (
    <a class="py-1 px-2 no-underline menu--item" href={linkItem.pathname} target="_blank" rel="noreferrer noopener">
      {linkItem.title}
    </a>
  ) : (
    <p>{ReactHtmlParser(linkItem.title)}</p>
  ));
});

const MenuOpenedView = ({ onToggle, onLogout, links }) => {
  return (
    <div className={classNames('menu bg-white z-50 relative shadow-xl rounded ')}>
      <button
        className={classNames('px-2 py-3 absolute top-0 right-0')}
        onClick={onToggle}
        height="14"
        width="14"
      >
        <svg
          height="14"
          width="14"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 14"
          fill="black"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M8.23,7l5.52-5.52a.89.89,0,0,0,0-1.23.89.89,0,0,0-1.23,0L7,5.77,1.48.25A.89.89,0,0,0,.25.25a.89.89,0,0,0,0,1.23L5.77,7,.25,12.52a.85.85,0,0,0-.25.61.88.88,0,0,0,.25.62.89.89,0,0,0,1.23,0L7,8.23l5.52,5.52a.85.85,0,0,0,.61.25.88.88,0,0,0,.62-.25.89.89,0,0,0,0-1.23Z" />
            </g>
          </g>
        </svg>
      </button>
      <div className={classNames('items-start px-3 py-5')}>
        {links.map((linkItem, index) => {
          var item = (linkItem.spacer ? (
            <div key={linkItem}><hr key={index+'hr'} className='mt-5 mb-2' />
             <div className="p-2" ><MenuItemView linkItem={linkItem} onClick={onToggle} /></div>
            </div>
          ) : (
            linkItem.isHidden ? <></> : <div className="p-2" key={index} ><MenuItemView linkItem={linkItem} onClick={onToggle} /></div>
          ))
          return item
        })}
        <div className="p-2"  key="logout" ><MenuItemView linkItem={{title:"Logout", type: LinkType.Internal}} onClick={onLogout} /></div>
      </div>
    </div>
  );
};

export default withRouter(Menu);
export { MenuView };
