import React, { useContext, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import Error from '../components/common/Error';
import Loading from '../components/common/Loading';
import { AssessmentSteps, OnboardingStep } from '../constants';
import { useAuth } from './useAuth';
import { useJourney } from './useJourney';

export const CareerKeyContext = React.createContext();
export const useCareerKey = () => useContext(CareerKeyContext);

const workgroupQuery = ({ journeyId, workgroupId, accessToken }) => ({
  method: 'GET',
  endpoint: `/journey/assessment/career-key/workgroup?journeyId=${journeyId}&workgroupId=${workgroupId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export const CareerKeyProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const [error, setError] = useState(false);

  const [careerKeySurvey, setCareerKeySurvey] = useState(null);
  
  const [careerKeyResult, setCareerKeyResult] = useState(null);
  const [filters, setFilters] = useState([]);

  const [bookmarks, setBookmarks] = useState([]);

  const [showDetails, setShowDetails] = useState(null);
  const [showRelated, setShowRelated] = useState(false);

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isCompleting, setIsCompleting ] = useState(false);

  const [lazyLoadedWorkgroups, setLazyLoadedWorkgroups] = useState({});
  const { query } = useClient();
  const { accessToken } = useAuth();
  const { journey, loadOwnJourney, isJourneyLoaded } = useJourney();


  const getCareerKey = async (test) => {

  }

  const careerKeyResultQuery = ({ journeyId, accessToken }) => ({
    method: 'GET',
    endpoint: '/journey/assessment/career-key/result?journeyId=' + journeyId,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const careerKeyQuery = ({ journeyId, accessToken }) => ({
    method: 'GET',
    endpoint: `/journey/assessment/career-key?journeyId=${journeyId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  

  const careerKeyFilterMutation = ({ accessToken, payload }) => ({
    method: 'POST',
    body: payload,
    endpoint: '/journey/assessment/career-key/filters',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const careerKeyMutation = ({ accessToken, payload }) => ({
    method: 'POST',
    body: payload,
    endpoint: '/journey/assessment/career-key',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const loadCareerKeyResult = async () => {
    console.log("loading career key");
    const { error, payload: response } = await query(
      careerKeyResultQuery({ journeyId: journey.id, accessToken })
    );

    if (error || (response && !response.success)) {
      if (!response || !response.errors) {
        setError('An unknown error occured.');
      } else {
        setError(
          Object.keys(response.errors)
            .map(p => response.errors[p])
            .join(', ')
        );
      }
    } else if (response && response.success) {
      setError(null);
      setCareerKeyResult(response.result);
      setFilters(response.result.selectedFilterIds);
    }
  };

  const loadCareerKeySurvey = async () => {

    if(isJourneyLoaded){
      const { error, payload: response } = await query(
        careerKeyQuery({ journeyId: journey.id, accessToken })
      );
  
      if (error || (response && !response.success)) {
        if (!response || !response.errors) {
          setError('An unknown error occured.');
        } else {
          setError(
            Object.keys(response.errors)
              .map(p => response.errors[p])
              .join(', ')
          );
        }
      } else if (response && response.success) {
        setError(null);
        setCareerKeySurvey(response.result);
      }
    }
    else{
      loadOwnJourney();
    }
  };

  useEffect(() => {
    if(journey && journey.onboardingStep >= OnboardingStep.Complete && journey.assessmentStep >= AssessmentSteps.CareerKeyCompleted){
      loadCareerKeySurvey();
      loadCareerKeyResult();
    }
  }, [journey]);
  
  useEffect(() =>{
    if(careerKeyResult){
      setBookmarks(  [...(careerKeyResult.careers || []), ...(careerKeyResult.majors || [])].filter(
        x => (careerKeyResult.bookmarkedIds || []).indexOf(x.id) >= 0
      ))
    }    
  }, [careerKeyResult]);


  const loadWorkgroup = async workgroupId => {
    const { error, payload: response } = await query(
      workgroupQuery({ journeyId: journey.id, workgroupId, accessToken })
    );

    if (error || (response && !response.success)) {
      if (!response || !response.errors) {
        setError('An unknown error occured.');
      } else {
        setError(
          Object.keys(response.errors)
            .map(p => response.errors[p])
            .join(', ')
        );
      }
    } else if (response && response.success) {
      setError(null);
      setLoading(false);
      return { majors: response.majors, careers: response.careers };
    }
    setLoading(false);
  };

  const findOrGetCareerOrMajorByIdAndWorkgroup = async (careerKey, { id, workgroupId }) => {
    setLoading(true);

    var item =
      (careerKey.careers || []).find(x => x.id === id) ||
      (careerKey.majors || []).find(x => x.id === id) ||
      null;

    if (item == null) {
      var workgroup = lazyLoadedWorkgroups[workgroupId];

      if (!workgroup) {
        workgroup = await loadWorkgroup(workgroupId);
        setLazyLoadedWorkgroups({
          ...lazyLoadedWorkgroups,
          [workgroupId]: workgroup,
        });
      }

      item =
        (workgroup.careers || []).find(x => x.id === id) ||
        (workgroup.majors || []).find(x => x.id === id) ||
        null;
    }
    setLoadingItem(item);
    setLoading(false);
  };

  var loader = null;
  var errorComponent = null;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    errorComponent = <Error message={error} />;
  }

  return (
    <CareerKeyContext.Provider
      value={{
        loadWorkgroup,
        loadCareerKeyResult,
        findOrGetCareerOrMajorByIdAndWorkgroup,
        showDetails,
        setShowDetails,
        showRelated,
        setShowRelated,
        loadingItem,
        careerKeySurvey,
        setCareerKeySurvey,
        careerKeyResult,
        setCareerKeyResult,
        filters,
        setFilters,
        error,
        setError,
        careerKeyFilterMutation,
        bookmarks,
        setBookmarks,
        careerKeyQuery,
        careerKeyResultQuery,
        careerKeyMutation,
        isSubmitting,
        setIsSubmitting,
        isCompleting,
        setIsCompleting
      }}
    >
      {errorComponent}
      {loader}
      {children}
    </CareerKeyContext.Provider>
  );
};
