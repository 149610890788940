import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import Loading from '../components/common/Loading';
import { useAuth } from '../hooks/useAuth';

export const JourneyContext = React.createContext();
export const useJourney = () => useContext(JourneyContext);

const journeysQuery = accessToken => ({
  method: 'GET',
  endpoint: '/journeys',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const journeyQuery = accessToken => ({
  method: 'GET',
  endpoint: '/journey',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});



const onboardingStepMutation = ({ payload, accessToken }) => ({
  method: 'POST',
  endpoint: '/journey/onboarding/step',
  body: payload,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const assessmentStepMutation = ({ payload, accessToken }) => ({
  method: 'POST',
  endpoint: '/journey/assessment/step',
  body: payload,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const feedbackStepMutation = ({ payload, accessToken }) => ({
  method: 'POST',
  endpoint: '/fellow-traveler/step',
  body: payload,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const parseJourneyId = () => {
  const parsedQs = queryString.parse(window.location.search);

  if (!parsedQs || !parsedQs.journeyId) {
    return null;
  }

  const id = parseInt(parsedQs.journeyId);

  return isNaN(id) ? null : id;
};

export const JourneyProvider = ({ children }) => {
  const [journey, setJourney] = useState(null);
  const [journeys, setJourneys] = useState(null);
  const [journeyId, setJourneyId] = useState(parseJourneyId());
  const [isJourneyLoaded, setIsJourneyLoaded] = useState(false);
  const [isJourneysLoaded, setIsJourneysLoaded] = useState(false);
  const [assignedStrengths, setAssignedStrengths ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingJourney, setIsLoadingJourney] = useState(false);
  const [reloadJourneys, setReloadJourneys] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated, user, accessToken } = useAuth();
  const { query } = useClient();

  const [cliftonStrengthsFirstConfirmation, setCliftonStrengthsFirstConfirmation] = useState(false);
  const [cliftonStrengthsSecondConfirmation, setCliftonStrengthsSecondConfirmation] = useState(false);

  const { loading: onboardingLoading, mutate: onboardingStepMutate } = useMutation(
    onboardingStepMutation
  );
  const { loading: assessmentLoading, mutate: assessmentStepMutate } = useMutation(
    assessmentStepMutation
  );
  const { loading: feedbackStepLoading, mutate: feedbackStepMutate } = useMutation(
    feedbackStepMutation
  );

  const updateJourney = async delta => {
    setJourney({ ...journey, ...delta });
  };

  const setJourneyOnboardingStep = async step => {
    const { payload } = await onboardingStepMutate({
      payload: {
        journeyId: journey.id,
        onboardingStep: step,
      },
      accessToken,
    });

    if (payload.success) {
      setJourney({ ...journey, onboardingStep: step });
    } else {
      setError(payload.errors);
    }
  };

  const setJourneyAssessmentStep = async (step, reload = false) => {
    if(step <= journey.assessmentStep){
      return;
    }
    const { payload } = await assessmentStepMutate({
      payload: {
        journeyId: journey.id,
        assessmentStep: step,
      },
      accessToken,
    });

    if(payload){
      if (payload?.success) {
        setJourney({ ...journey, assessmentStep: step });
        if(reload){
          loadOwnJourney();
        }
      } else {
        setError(payload.errors);
      }
    }    
  };

  const setFeedbackStepCliftonStrengthsfinder = async step => {
    const { payload } = await feedbackStepMutate({
      payload: {
        journeyId: journey.id,
        feedbackStepCliftonStrengthsfinder: step,
      },
      accessToken,
    });
    if (payload.success) {
      setJourney({
        ...journey,
        fellowTravelerFeedbackStepCliftonStrengthsfinder: step,
      });
    } else {
      setError(payload.errors);
    }
  };

  const setFeedbackStepCoreValues = async step => {
    const { payload } = await feedbackStepMutate({
      payload: {
        journeyId: journey.id,
        feedbackStepCoreValues: step,
      },
      accessToken,
    });
    if (payload.success) {
      setJourney({ ...journey, fellowTravelerFeedbackStepCoreValues: step });
    } else {
      setError(payload.errors);
    }
  };

  const setFeedbackStepCareerKey = async step => {
    const { payload } = await feedbackStepMutate({
      payload: {
        journeyId: journey.id,
        feedbackStepCareerKey: step,
      },
      accessToken,
    });
    if (payload.success) {
      setJourney({ ...journey, fellowTravelerFeedbackStepCareerKey: step });
    } else {
      setError(payload.errors);
    }
  };

  const reset = () => {
    setJourneys(null);
    setJourney(null);
    setJourneyId(null);
    setIsJourneyLoaded(false);
    setIsJourneysLoaded(false);
    setIsLoading(false);
    setIsLoadingJourney(false);
    setError(null);
  };

  const ownJourney = journeys && journeys.find(x => x.ownJourney === true);
  const isOwnJourneySelected = ownJourney && ownJourney.id === journeyId;
  const isJourneySelected = journeyId != null;

  const loadJourneys = async () => {
    setIsLoading(true);
    const { error, payload: response } = await query(journeysQuery(accessToken));

    if (error || (response && !response.success)) {
      if (!response || !response.errors) {
        setError('An unknown error occured.');
      } else {
        setError(
          Object.keys(response.errors)
            .map(p => response.errors[p])
            .join(', ')
        );
      }
    } else if (response && response.success) {
      setError(null);

      if (response.journeys.length === 1 && journeyId == null) {
        setJourneyId(response.journeys[0].id);
      }
      setJourneys(response.journeys);
      setIsJourneysLoaded(true);
    }
    setIsLoading(false);
    setReloadJourneys(false);
  };  

  useEffect(() => {    
    if (isAuthenticated) {
      loadJourneys();
    }
  }, [isAuthenticated, user, accessToken, query, reloadJourneys]);


  const loadOwnJourney = async () => {
    if (!isLoadingJourney && isAuthenticated && isJourneysLoaded && !!journeyId) {
      setError(null);
      setJourney(null);
      setIsJourneyLoaded(false);
      setIsLoadingJourney(true);
      const { error, payload: response } = await query(journeyQuery(accessToken));

      if (error || (response && !response.success)) {
        if (!response || !response.errors) {
          setError('An unknown error occured.');
        } else {
          setError(
            Object.keys(response.errors)
              .map(p => response.errors[p])
              .join(', ')
          );
        }
      } else if (response && response.success) {
        setError(null);
        setJourney(response);
        setIsJourneyLoaded(true);
      }
      setIsLoadingJourney(false);
    }
  };

  useEffect(() => {
      loadOwnJourney();
  }, [
    isAuthenticated,
    user,
    accessToken,
    query,
    journeyId,
    isOwnJourneySelected,
    isJourneysLoaded,
  ]);

  var loader = null;

  if (onboardingLoading || assessmentLoading || feedbackStepLoading || isLoading) {
    loader = <Loading />;
  }

  const doReloadJourneys = () => setReloadJourneys(true);

  return (
    <JourneyContext.Provider
      value={{
        error,
        journeyId,
        journey,
        journeys,
        doReloadJourneys,
        setJourneyId,
        setJourney,
        updateJourney,
        isJourneyLoaded,
        isJourneysLoaded,
        ownJourney,
        isJourneySelected,
        isOwnJourneySelected,
        setJourneyOnboardingStep,
        setJourneyAssessmentStep,
        setFeedbackStepCliftonStrengthsfinder,
        setFeedbackStepCoreValues,
        setFeedbackStepCareerKey,
        loadOwnJourney,
        reset,
        assignedStrengths,
         setAssignedStrengths ,
         cliftonStrengthsFirstConfirmation,
         setCliftonStrengthsFirstConfirmation,
         cliftonStrengthsSecondConfirmation,
         setCliftonStrengthsSecondConfirmation
      }}
    >
      {loader}
      {children}
    </JourneyContext.Provider>
  );
};
