import React, { useContext, useState } from 'react';
import { ZoeLogoType } from '../components/common/ZoeLogo';

export const PageContext = React.createContext();
export const usePage = () => useContext(PageContext);

export const PageProvider = ({ children }) => {
  const [title, setTitle] = useState('Zoe');

  const [backgroundClasses, setBackgroundClasses] = useState('');
  const [headerVisible, setHeaderVisible] = useState(true);
  const [subheaderVisible, setSubheaderVisible] = useState(false);
  const [subheaderText, setSubheaderText] = useState('');
  const [menuVisible, setMenuVisible] = useState(true);
  const [paddingDisabled, setPaddingDisabled] = useState(false);
  const [logoVisible, setLogoVisible] = useState(true);
  const [stepsProgressVisible, setStepsProgressVisible] = useState(true);
  const [totalSteps, setTotalSteps] = useState(0);
  const [currentProgressStep, setCurrentProgressStep] = useState(0);

  const [menuCurrentStep, setMenuCurrentStep] = useState();
  const [menuCurrentStage, setMenuCurrentStage] = useState();
  const [headerLogoType, setHeaderLogoType] = useState(ZoeLogoType.White);

  const setHeaderVisibleWithMenu = value => {
    setHeaderVisible(value);
    setMenuVisible(value);
  };

  const resetPage = () => {
    setTitle('Zoë');
    setBackgroundClasses('');
    setHeaderVisible(true);
    setSubheaderVisible(false);
    setSubheaderText('');
    setMenuVisible(true);
    setPaddingDisabled(false);
    setLogoVisible(false);
    setStepsProgressVisible(false);
    
    setTotalSteps(0);
    setCurrentProgressStep(0);
  };

  const setDisableBackgroundScrollable = disable => {
    if (disable) {
      document.body.classList.add('disable-scrolling');
    } else {
      document.body.classList.remove('disable-scrolling');
    }
  };

  return (
    <PageContext.Provider
      value={{
        title,
        setTitle,
        backgroundClasses,
        setBackgroundClasses,
        headerVisible,
        setHeaderVisible: setHeaderVisibleWithMenu,
        subheaderVisible,
        setSubheaderVisible,
        subheaderText,
        setSubheaderText,
        paddingDisabled,
        setPaddingDisabled,
        menuVisible,
        setMenuVisible,
        resetPage,
        setDisableBackgroundScrollable,
        logoVisible,
        setLogoVisible,
        stepsProgressVisible,
        setStepsProgressVisible,
        totalSteps,
        setTotalSteps,
        currentProgressStep,
        setCurrentProgressStep,
        headerLogoType,
        setHeaderLogoType,
        menuCurrentStep,
        setMenuCurrentStep,
        menuCurrentStage,
        setMenuCurrentStage
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
