import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { useAuth } from '../hooks/useAuth';

const AuthorizedRoute = ({ component: Component, path, location, ...rest }) => {
  const { isAuthenticated, ready } = useAuth();

  const render = props => <Component {...props} />;

  if (!ready) {
    return <Loading />;
  }

  return isAuthenticated ? (
    <Route path={path} location={location} render={render} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        search:
          path !== '/'
            ? `?returnUrl=${location.pathname}${location.search}`
            : ''
      }}
    />
  );
};

export default AuthorizedRoute;
