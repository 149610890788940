import { createClient } from 'react-fetching-library';
import { requestHostInterceptor } from './requestInterceptors/requestHostInterceptor';
import { refreshTokenInterceptor } from './responseInterceptors/refreshTokenInterceptor';
import errorInterceptor from './responseInterceptors/errorInterceptor';
import config from '../config';

const Client = (logApiClientError, logWarning, logDebug) => {
  return createClient({
    requestInterceptors: [requestHostInterceptor(config.api.uri)],
    responseInterceptors: [
      refreshTokenInterceptor(logWarning, logDebug),
      errorInterceptor(logApiClientError),
    ],
  });
};

export { Client as default };
